import React, { useState } from "react";
import { Switch, Route } from "react-router-dom";
import Logout from "../auth/Logout";
import Register from "./Auth/Register";
import "./style.css";
import { useSelector } from "react-redux";
import PegogLogo from "./Auth/assets/pedgog-logo.svg";
import IllumineLogo from "./Auth/assets/illumine-logo.svg";
import PedgogLogo from "./Auth/assets/pedgogLogo2.svg";
import Board from "./Auth/assets/board.svg";
import { Redirect } from "react-router";
import Login from "./Auth/Login";
import { Button } from "@material-ui/core";
import { mdiEmail, mdiGoogle, mdiCellphone } from "@mdi/js";
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import store from "../../Redux_Store";

export default ({ }) => {
  const { auth, app } = useSelector((state) => state);
  const [mobileType, setMobileType] = useState("mobileOTP");
  const [accesscodeData, setAccessCodeData] = useState();
  let [enterAccesscode, setEnterAccessCode] = useState(false)
  let p1 = window.location.pathname
  let register = p1.includes('register')

  const handleBackBtn = () => {
    if (!register) { store.dispatch({ type: 'RESET_LOGIN_OTP' }) }
    else if (enterAccesscode && accesscodeData?.accessCode) { setAccessCodeData(null) }
    else setEnterAccessCode(false)
  }

  return (
    <div className="main-container"
      style={!register ? {
        justifyContent: "space-around", alignItems: "center", padding: "0rem 2rem", background: "#fff"
      } : {

      }}
    >
      {
        !register ? app?.loginOTP?.success &&
          null :
          enterAccesscode && <div
            style={{ zIndex: 10, width: "100%", display: 'flex', alignItems: 'center', color: '#484848', fontWeight: 400, cursor: 'pointer', fontSize: '0.9rem', position: "absolute", top: "6vh", left: '5vw' }}
            onClick={() => handleBackBtn()} >
            <ArrowBackIosOutlinedIcon style={{ marginRight: "1.2%", width: "0.7em", fontSize: '0.9rem' }} />Back
          </div>
      }
      <div className='loginDiv' style={register ? { alignSelf: 'center', width: '44%' } : {}}>
        <div className="ap-login-field-container tct-scrollable-darkgray overFlowYAuto">
          <Switch>
            <Route
              path="/auth/login"
              render={() => (
                <Login
                  app={app}
                  auth={auth}
                  mobileType={mobileType}
                  setMobileType={setMobileType}
                />
              )}
            />

            <Route path="/logout" component={Logout} />
            <Route
              path="/auth/register"
              render={() =>
                <Register app={app} auth={auth}
                  accesscodeData={accesscodeData}
                  setAccessCodeData={setAccessCodeData}
                  enterAccesscode={enterAccesscode}
                  setEnterAccessCode={setEnterAccessCode}
                />}
            />
            {/* <Route path="/auth/forgotpassword" component={ForgotPassword} />
                            <Route path="/auth/resetpassword" component={ResetPassword} /> */}
            <Route
              path="/"
              render={() => (
                <Login
                  app={app}
                  auth={auth}
                  mobileType={mobileType}
                  setMobileType={setMobileType}
                />
              )}
            />
          </Switch >
        </div >

        {/* {!register && <div className="ap-gray-color-1 text-center ir-copyright">
          By clicking on Login, I accept the{" "}
          <a
            href="https://illumine.in/terms-of-use.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms & Conditions
          </a>{" "}
          &{" "}
          <a
            href="https://illumine.in/privacy-policy.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
        </div>} */}
      </div >
      <div style={{ position: "absolute", bottom: "4vh", left: "3vw", width: "100%", zIndex: 0 }}>
        <img src={IllumineLogo} alt="IllumineLogo" style={{ width: "265px", height: '87px' }} />
        <div
          // className="ap-gray-color-1 ir-copyright"
          style={{ fontSize: "0.7rem", margin: "0", color: '#656565' }}
        >
          Copyright 2019-25; Illumine. All rights reserved.
        </div>
      </div>
      {register ? null : <div style={{ position: "absolute", top: "4vh", textAlign: "center", width: "100%", zIndex: 0 }}>
        <img src={PedgogLogo} alt="IllumineLogo" style={{ width: "15vw", height: '15vh' }} />
      </div>
      }
      {auth?.isLoggedIn && (<Redirect to={{ pathname: "/application" }} />)}
    </div >
  );
};




