import { Button } from "@material-ui/core";
import { mdiCellphone, mdiEmail } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useEffect } from "react";
import EmailPassword from "./EmailPassword";
import MobileOTP from "./MobileOTP";
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
export default ({ auth, app, mobileType, setMobileType }) => {
  return (
    <>
      <div
        className="ap-login-field-container tct-scrollable-darkgray overFlowYAuto"
        style={{
          border: '2px solid #DEDEDE', boxShadow: "0px 4px 4px 0px #B5B5B540",
          padding: "2rem 1.5rem", backgroundColor: 'white', borderRadius: '22px', maxWidth: '30vw',
          marginTop: mobileType == 'mobileOTP' ? '2vh' : '12vh'
        }}
      >
        {mobileType === "mobileOTP" ?
          <MobileOTP app={app} />
          :
          <EmailPassword />
        }
      </div >
      {!app?.loginOTP?.success && < div style={{ position: "relative", alignItems: "center", justifyContent: 'center', display: "flex", marginTop: "5%", padding: '0px 50px', zIndex: 1000, cursor: "pointer" }} onClick={() => { setMobileType(mobileType === "emailPassword" ? 'mobileOTP' : "emailPassword") }}>
        <div
          style={{
            textAlign: "center",
            fontSize: '16px', fontWeight: 400
          }}
        >
          or login using
        </div>
        <div style={{
          borderBottom: "1px solid", marginLeft: "10px", display: "flex", alignItems: "center",
        }} >
          {mobileType !== "emailPassword" ? <EmailIcon /> : <PhoneIcon />}
          <div style={{ fontSize: '16px', display: "flex", justifyContent: "center" }}>
            <div style={{}}>{mobileType === "emailPassword" ? 'Phone' : "Email"}</div>
          </div>
        </div>
      </div >
      }
    </>
  );
};
