import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import NavLink from "./NavLink";
import { connect, useSelector } from "react-redux";
import './style.css'
import { t } from "i18next";
import Profile from "../../../components/Profile";
import Modal from "../../../components/walkThrough/modal";
import { getAnalyticsForProgram } from "../../application/redux_actions";

const styles = {};
const logo = require('./assets/pedgog.svg');
const pedgogLogo = require('./assets/pedgogLogo2.0.svg');
const grp = require('./assets/conduct.svg');
const resourceSvg1 = require('./assets/resourceSvg1.svg');
// class LeftNav extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       accesscode: "",
//       animate: false,
//       offline: false,
//       language: props.language
//     }
//     this.handleClick = this.handleClick.bind(this);
//   }

//   handleClick(e) {
//     this.setState((prevState) => {
//       return { animate: !prevState.animate }
//     });
//   }

//   async componentDidMount() {
//     let { user } = this.props.app || {}
//     let { user_token } = this.props.auth || {}
//     if (user && user_token && user.programId) {
//       getAnalyticsForProgram({ token: user_token, userId: user._id, programId: user.programId })
//     }
//   }

//   routeChange() {
//     let path = `/application`;
//     this.props.history.push(path);
//   }



// }

// LeftNav.propTypes = {
//   classes: PropTypes.object.isRequired,
//   authMsg: PropTypes.string
// };

// const mapStateToProps = state => ({
//   auth: state.auth,
//   app: state.app
// });

// export default connect(mapStateToProps, null)(withStyles(styles)(LeftNav));


const LeftNav = ({ }) => {
  let { app, auth } = useSelector(state => state)
  let { walkThrough, user, availableProgramsNew } = app || {}
  let { programId, analytics, ratingAccess, _id } = user || {}
  let { user_token } = auth
  let program = availableProgramsNew[programId] || {}
  let { showResource } = program || {}
  let { currentEl, allElements, stop } = walkThrough;

  useEffect(() => {
    getAnalyticsForProgram({ token: user_token, userId: _id, programId })
  }, [programId])
  const isAnalyticsAvailable = analytics && analytics?.dashboard?.[0]?.programs.includes(programId)
  console.log("analytics", analytics, programId)

  return (
    <div className={"ap-left-nav h-100"}>
      <form className={"h-100"}>
        <nav className={"h-100"}>
          <ul className={"h-100"} style={{ width: '87px' }}>
            <div>
              <div style={{ padding: '1rem 0.5rem' }}>
                <img src={pedgogLogo} alt="logo" style={{ display: 'block', }} />
                {/* <div style={{ fontSize: "0.6rem", color: "#727272", borderTop: '1px solid #727272', margin: "0.7rem 0rem", padding: "0.5rem 0rem" }}>A Coaching Platform by Illumine</div> */}
              </div>
              <div className={!stop && allElements[currentEl] == 'walkProgramDiv' ? 'zIndexPos' : ''}>
                {!stop && allElements[currentEl] == 'walkProgramDiv' && <Modal id={'walkProgramDiv'} Comp={false} />}
                <NavLink to="/application" className={!stop && allElements[currentEl] == 'walkProgramDiv' ? 'onClickDisabled' : ''}>
                  <img src={grp} alt="grp" className="ap-nav-img" />
                  <label className='ap-nav-link-profile-text'>{t('PROGRAM')}</label>
                </NavLink>
              </div>
              {showResource ?
                <div className={!stop && allElements[currentEl] == 'showBlog' ? 'zIndexPos' : ''}>
                  {!stop && allElements[currentEl] == 'showBlog' && <Modal id={'showBlog'} Comp={false} />}
                  <NavLink to="/application/blog" id={!stop && allElements[currentEl] == 'showBlog' ? 'showBlog' : ''}>
                    <img src={resourceSvg1} alt="grp" className="ap-nav-img" />
                    <label className='ap-nav-link-profile-text'>{t('RESOURCES')}</label>
                  </NavLink>
                </div>
                : null}
              {isAnalyticsAvailable ? <NavLink to="/batches" className={!stop && allElements[currentEl] == 'walkProgramDiv' ? 'onClickDisabled' : ''}>
                <img src={grp} alt="grp" className="ap-nav-img" />
                <label className='ap-nav-link-profile-text'>{t('Batch Analytics')}</label>
              </NavLink> : null}
              {/* {analytics?.dashboard?.length > 0 ?
                  <div className={!stop && allElements[currentEl] == 'analytics' ? 'zIndexPos' : ''}>
                    {!stop && allElements[currentEl] == 'analytics' && <Modal id={'analytics'} Comp={false} />}
                    <NavLink to="/application/analytics" id={!stop && allElements[currentEl] == 'analytics' ? 'analytics' : ''}>
                      <img src={grp} alt="grp" className="ap-nav-img" />
                      <label className='ap-nav-link-profile-text'>{t('ANALYTICS')}</label>
                    </NavLink>
                  </div>
                  : null} */}
              {/* {ratingAccess ? <NavLink to="/application/rating">
                  <img src={grp} alt="grp" className="ap-nav-img" />
                  <label className='ap-nav-link-profile-text'>{t('RATING')}</label>
                </NavLink> : null} */}
            </div>
            <div className='last-div' style={{ justifyContent: 'flex-end' }}>
              {/* <NavLink to="/logout">
                  <img src={logoutIcon} alt="ap-nav-img" className="ap-nav-img" />
                  <label>{t('LOGOUT')}</label>
                </NavLink> */}
              <Profile />
            </div>
          </ul>
        </nav>
      </form>
    </div >
  );

}
export default LeftNav;